import { Controller } from "@hotwired/stimulus";
import { Popover } from "bootstrap";

// Connects to data-controller="popover"
export default class extends Controller {
  connect() {
    const popoverTriggerEl = this.element as HTMLElement;

    new Popover(
      popoverTriggerEl,
      {
        html: true,
        trigger: "hover",
        placement: "auto",
        fallbackPlacements: ["right", "left", "top", "bottom"],
      },
    );
  }
}
