import { Controller } from "@hotwired/stimulus";
import moment, { Moment } from "moment";
import * as bootstrap from "bootstrap";

// Connects to data-controller="timestamp"
export default class extends Controller {
  connect() {
    this.bindTimestamp(this.element as HTMLElement);
  }

  bindTimestamp(element: HTMLElement) {
    const dataset = element.dataset;
    const timestamp: Moment = moment(dataset.timestamp);
    let output: Moment | string = timestamp.toString();

    if (dataset.format) {
      output = timestamp.format(dataset.format.trim());
    }

    element.dataset.bsTitle = output.toString();
    element.innerHTML = timestamp.fromNow();

    new bootstrap.Tooltip(element);
  }
}
