import { Controller } from "@hotwired/stimulus"
import hljs from "highlight.js";
import "highlight.js/styles/base16/mocha.css";

// Connects to data-controller="syntax-highlighter"
export default class extends Controller {
  connect() {
    const element = this.element as HTMLElement;
    const code = this.removeIndentation(element.innerText);

    element.innerHTML = hljs.highlight(
      code,
      {
        language: element.dataset.language as string
      }
    ).value;
  }

  private removeIndentation(code: string): string {
    const lines = code.split("\n");
    const nonEmptyLines = lines.filter(line => line.trim().length > 0);

    const commonIndent = nonEmptyLines.reduce((min, line) => {
      const match = line.match(/^\s*/);
      const indent = match ? match[0].length : 0;

      return Math.min(min, indent);
    }, Infinity);

    return lines.map(line => line.slice(commonIndent)).join("\n").trim();
  }
}
