import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="gatekeeper"
export default class extends Controller {
  private declare clickCountValue: number;
  private lastClickTime: number = 0;
  private readonly clickInterval: number = 1500; // milliseconds
  private readonly clickAmount: number = 3;

  connect(): void {
    this.clickCountValue = 0;
    this.lastClickTime = 0;

    this.element.addEventListener("click", () => this.handleClick());
  }

  handleClick(): void {
    const currentTime = new Date().getTime();

    if ((currentTime - this.lastClickTime) <= this.clickInterval) {
      this.clickCountValue++;
    } else {
      this.clickCountValue = 1;
    }

    this.lastClickTime = currentTime;

    if (this.clickCountValue === this.clickAmount) {
      this.onClickComplete();
      this.clickCountValue = 0;
    }
  }

  private onClickComplete(): void {
    window.location.href = "dungeon";
  }
}
