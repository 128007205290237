import { Controller } from "@hotwired/stimulus";
import moment from "moment";
import { GRID_BREAKPOINTS } from "../entrypoints/application";

// Connects to data-controller="clock"
export default class extends Controller {
  static targets = ["timeDisplay"];

  connect() {
    this.updateTime();

    setInterval(() => {
      this.updateTime();
    }, 1000);
  }

  updateTime() {
    this.timeDisplayTarget.innerHTML = moment().format(this.timeFormat());
  }

  timeFormat() {
    if (screen.width >= GRID_BREAKPOINTS.md) {
      return "dddd, MMMM Do 1YYYY, h:mm:ss a";
    } else if (screen.width >= GRID_BREAKPOINTS.sm) {
      return "1YYYY-MM-DD LTS";
    } else {
      return "LTS";
    }
  }
}
