import "@hotwired/turbo-rails";
import * as bootstrap from "bootstrap"
import { ready } from "../src/helpers"

window.bootstrap = bootstrap;

export const GRID_BREAKPOINTS = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
};

import "../controllers";

function bindTurboModal() {
    let elem = document.getElementById("turbo-modal");
    if (elem == null) return;

    // Remove the content once it is hidden
    elem.addEventListener("hidden.bs.modal", _event => {
        document.getElementById("turbo_modal").innerHTML = "";
    });
}

ready(() => {
    bindTurboModal();
});
