import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js";
import "choices.js/public/assets/styles/choices.css";

// Connects to data-controller="tag-selector"
export default class extends Controller {
  connect() {
    let element = this.element as HTMLElement;
    if (element.classList.contains("choices__input")) return;

    new Choices(element, { duplicateItemsAllowed: false, });
  }
}
